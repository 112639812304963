// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '../../../auth/jwt/useJwt'
import * as url from '../../../helpers/url_helper'
import toast from 'react-hot-toast'
import ToastContent from '../../ui-elements/toast'

// ** for Loading Spinner
export const showLoading = createAsyncThunk('appWorkflow/showLoading', async status => {
  return status
})

export const getAllData = createAsyncThunk('appWorkflow/getAllData', async (id, { dispatch }) => {
  const response = await useJwt.get(url.GET_WORKFLOW)
  if (response) {
    dispatch(showLoading(false))
  }
  return response.payload
})

export const getWorkFlowActions = createAsyncThunk('appWorkflow/getWorkFlowActions', async (id, { dispatch }) => {
  const response = await useJwt.get(url.GET_WORKFLOW_ACTIONS)
  if (response) {
    dispatch(showLoading(false))
  }
  return response.payload
})

export const getWorkFlowTasks = createAsyncThunk('appWorkflow/getWorkFlowTasks', async (id, { dispatch }) => {
  const response = await useJwt.get(url.GET_WORKFLOW_STATE_TASKS)
  if (response) {
    dispatch(showLoading(false))
  }
  return response.payload
})

export const addWorkflow = createAsyncThunk('appWorkflow/addWorkflow', async (form, { dispatch }) => {
  await useJwt.post(url.CREATE_WORKFLOW, form)
    .then(async () => {
      dispatch(showLoading(false))
      toast.success(() => (
        <ToastContent message="Workflow Created Successfully" />
      ), {
        position: "top-center",
        reverseOrder: false
      })
      await dispatch(getAllData())
    })
    .catch((res) => {
      dispatch(showLoading(false))
      toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
        position: "top-center",
        reverseOrder: false
      })

    })

  return form
})

export const updateWorkflow = createAsyncThunk('appWorkflow/updateWorkflow', async (form, { dispatch }) => {
  await useJwt.put(url.UPDATE_WORKFLOW, form)
    .then(async () => {
      dispatch(showLoading(false))
      toast.success(() => (
        <ToastContent message="Workflow Updated Successfully" />
      ), {
        position: "top-center",
        reverseOrder: false
      })
      await dispatch(getAllData())
    })
    .catch((res) => {
      dispatch(showLoading(false))
      toast.error(() => <ToastContent message={res.response?.data?.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
        position: "top-center",
        reverseOrder: false
      })

    })

  return form
})

export const deleteFlow = createAsyncThunk('appWorkflow/deleteFlow', async (id, { dispatch }) => {
  await useJwt.delete(url.DELETE_FORM_TYPE, { id })
  await dispatch(getAllData())
  dispatch(showLoading(false))
  return id
})

export const getAvailableTransitions = createAsyncThunk('appWorkflow/getAvailableTransitions', async (IDs, { dispatch }) => {
  // const response = await useJwt.get(`${url.GET_AVAILABLE_TRANSITIONS_NEW}?workFlowItemType=${IDs.workFlowItemType !== undefined ? IDs.workFlowItemType : ""}${IDs.workItemID !== undefined ? `&workItemID=${IDs.workItemID}` : ''}${IDs.workItemID === undefined ? IDs.workItemTypeVersionID !== undefined ? `&workItemTypeID=${IDs.workItemTypeVersionID}` : '' : ''}`)
  const queryParameters = [
    `workFlowItemType=${IDs.workFlowItemType ? IDs.workFlowItemType : ""}`,
    IDs.workItemID ? `&workItemID=${IDs.workItemID}` : '',
    IDs.workItemTypeVersionID ? `&workItemTypeID=${IDs.workItemTypeVersionID}` : ''
  ].join('')

  const response = await useJwt.get(`${url.GET_AVAILABLE_TRANSITIONS_NEW}?${queryParameters}`)

  if (response) {
    dispatch(showLoading(false))
  }
  return response.payload
})

export const appWorkflowSlice = createSlice({
  name: 'appWorkflow',
  initialState: {
    allData: [],
    stateTasks: null,
    actions: null,
    availableTransitions: [],
    isLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getWorkFlowActions.fulfilled, (state, action) => {
        state.actions = action.payload
      })
      .addCase(getWorkFlowTasks.fulfilled, (state, action) => {
        state.stateTasks = action.payload
      })
      .addCase(getAvailableTransitions.fulfilled, (state, action) => {
        state.availableTransitions = action.payload
      })
      .addCase(showLoading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export default appWorkflowSlice.reducer

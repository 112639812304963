// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import useJwt from "../../../auth/jwt/useJwt"
import * as url from "../../../helpers/url_helper"
import toast from 'react-hot-toast'
import ToastContent from '../../ui-elements/toast'
// ** Axios Imports
import axios from "axios"

// ** for Loading Spinner
export const showLoading = createAsyncThunk(
  "appDuties/showLoading",
  async (status) => {
    return status
  }
)

export const fetchAllDuties = createAsyncThunk(
  "appDuties/fetchAllDuties",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_ALL_DUTIES}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const fetchEvents = createAsyncThunk(
  "appDuties/fetchEvents",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_OWN_DUTIES}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const fetchOwnDuties = createAsyncThunk(
  "appDuties/fetchOwnDuties",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_OWN_DUTIES}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const fetchUserDuties = createAsyncThunk(
  "appDuties/fetchUserDuties",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_USER_DUTIES}?userID=${id}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)


export const fetchDutyTypes = createAsyncThunk(
  "appDuties/fetchDutyTypes",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_DUTY_TYPES}`)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

// export const fetchEvents = createAsyncThunk('appCalendar/fetchEvents', async calendars => {
//   const response = await axios.get('/apps/calendar/events', { calendars })
//   return response.data
// })
export const addEvent = createAsyncThunk(
  "appDuties/addEvent",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.CREATE_OWN_DUTY, form)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Duty Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(fetchEvents())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const addEventUser = createAsyncThunk(
  "appDuties/addEventUser",
  async (form, { dispatch }) => {

    await useJwt
      .post(url.CREATE_DUTY, form)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Duty Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(fetchEvents())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const addDuty = createAsyncThunk(
  "appDuties/addDuty",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.CREATE_DUTY, form)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Duty Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(fetchEvents())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

// Perform Tasks 
export const performTask = createAsyncThunk(
  "appDuties/performTask",
  async (form, { dispatch }) => {
    await useJwt
      .post(`${url.PAYCARD_DO_STATE_TASK}?task_id=${form.ID}`, form)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Task Performed Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(fetchEvents())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

// export const addEvent = createAsyncThunk(
//   "appCalendar/addEvent",
//   async (event, { dispatch, getState }) => {
//     await axios.post("/apps/calendar/add-event", { event }) 
//     await dispatch(fetchEvents(getState().calendar.selectedCalendars)) 
//     return event 
//   }
// ) 

export const updateEvent = createAsyncThunk(
  "appDuties/updateEvent",
  async (event, { dispatch, getState }) => {
    await axios.post("/apps/calendar/update-event", { event })
    await dispatch(fetchEvents(getState().calendar.selectedCalendars))
    return event
  }
)

export const updateFilter = createAsyncThunk(
  "appDuties/updateFilter",
  (filter) => {
    return filter
  }
)

export const updateAllFilters = createAsyncThunk(
  "appDuties/updateAllFilters",
  (value) => {
    return value
  }
)

export const removeEvent = createAsyncThunk(
  "appDuties/removeEvent",
  async (id) => {
    await axios.delete("/apps/calendar/remove-event", { id })
    return id
  }
)

export const addBulkDuties = createAsyncThunk(
  "appDuties/addBulkDuties",
  async (form, { dispatch }) => {
    await useJwt
      .postDuty(url.CREATE_BULK_DUTY, form)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Duties Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(fetchAllDuties())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const appDutiesSlice = createSlice({
  name: "appDuties",
  initialState: {
    selectedCalendars: [],
    events: [],
    ownDuties: [],
    userDuties: [],
    filteredEvents: [],
    filteredUserEvents: [],
    dutyTypes: null,
    isLoading: false,
    selectedEvent: {},
    eventTypes: [],
    locationTypes: [],
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDuties.fulfilled, (state, action) => {
        const newEvents = []
        if (Object.keys(action.payload).length !== 0) {
          action.payload.map((item) => newEvents.push({
            id: item._id,
            title: item.GovernmentInsitute && item.GovernmentInsitute.GovInstitutionInfo
              ? `${item.GovernmentInsitute.GovInstitutionInfo.Name} (${item.dutyOwner.name})`
              : (item.PsScheme && item.PsScheme !== null ? `${item.PsScheme.SchemeName} (${item.dutyOwner.name})` : ''),
            start: item.startTime,
            end: item.endTime,
            textColor: item.meta ? item.meta.color ? '#ffffff' : '' : '',
            borderColor: item.meta ? item.meta.color ? item.meta.color : '#4b4b4b' : '#4b4b4b',
            color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            display: 'block',
            extendedProps: {
              calendar: item.dutyType,
              isArchived: item.isArchived,
              GovernmentInsitute: item.GovernmentInsitute,
              PsScheme: item.PsScheme && item.PsScheme !== null ? item.PsScheme : null,
              color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            }
          })
          )
        }
        state.events = newEvents

        const NewArr = []
        newEvents.map((item) => {
          state.selectedCalendars.map((cal) => {
            if (item.extendedProps.calendar === cal) {
              NewArr.push(item)
            }
          })
        })
        state.filteredEvents = NewArr

      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        const newEvents = []
        if (Object.keys(action.payload).length !== 0) {
          action.payload.map((item) => newEvents.push({
            id: item._id,
            title: item.GovernmentInsitute && item.GovernmentInsitute.GovInstitutionInfo
              ? `${item.GovernmentInsitute.GovInstitutionInfo.Name} (${item.dutyOwner.name})`
              : (item.PsScheme && item.PsScheme !== null ? `${item.PsScheme.SchemeName} (${item.dutyOwner.name})` : ''),
            start: item.startTime,
            end: item.endTime,
            textColor: item.meta ? item.meta.color ? '#ffffff' : '' : '',
            borderColor: item.meta ? item.meta.color ? item.meta.color : '#4b4b4b' : '#4b4b4b',
            color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            display: 'block',
            extendedProps: {
              calendar: item.dutyType,
              isArchived: item.isArchived,
              GovernmentInsitute: item.GovernmentInsitute,
              PsScheme: item.PsScheme && item.PsScheme !== null ? item.PsScheme : null,
              color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            }
          })
          )
        }
        state.events = newEvents

        const NewArr = []
        newEvents.map((item) => {
          state.selectedCalendars.map((cal) => {
            if (item.extendedProps.calendar === cal) {
              NewArr.push(item)
            }
          })
        })
        state.filteredEvents = NewArr

      })
      .addCase(fetchOwnDuties.fulfilled, (state, action) => {
        const newDuties = []
        if (Object.keys(action.payload).length !== 0) {
          action.payload.map((item) => newDuties.push({
            id: item._id,
            title: item.GovernmentInsitute && item.GovernmentInsitute.GovInstitutionInfo
              ? `${item.GovernmentInsitute.GovInstitutionInfo.Name} (${item.dutyOwner.name})`
              : (item.PsScheme && item.PsScheme !== null ? `${item.PsScheme.SchemeName} (${item.dutyOwner.name})` : ''),
            start: item.startTime,
            end: item.endTime,
            textColor: item.meta ? item.meta.color ? '#ffffff' : '' : '',
            borderColor: item.meta ? item.meta.color ? item.meta.color : '#4b4b4b' : '#4b4b4b',
            color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            display: 'block',
            extendedProps: {
              calendar: item.dutyType,
              isArchived: item.isArchived,
              GovernmentInsitute: item.GovernmentInsitute,
              color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            }
          })
          )
        }
        state.events = newDuties
        // state.filteredEvents = newEvents 
      })
      .addCase(fetchUserDuties.fulfilled, (state, action) => {
        const newDuties = []
        if (Object.keys(action.payload).length !== 0) {
          action.payload.map((item) => newDuties.push({
            id: item._id,
            title: item.GovernmentInsitute && item.GovernmentInsitute.GovInstitutionInfo
              ? `${item.GovernmentInsitute.GovInstitutionInfo.Name} (${item.dutyOwner.name})`
              : (item.PsScheme && item.PsScheme !== null ? `${item.PsScheme.SchemeName} (${item.dutyOwner.name})` : ''),
            start: item.startTime,
            end: item.endTime,
            textColor: item.meta ? item.meta.color ? '#ffffff' : '' : '',
            borderColor: item.meta ? item.meta.color ? item.meta.color : '#4b4b4b' : '#4b4b4b',
            color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            display: 'block',
            extendedProps: {
              calendar: item.dutyType,
              isArchived: item.isArchived,
              GovernmentInsitute: item.GovernmentInsitute,
              color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            }
          })
          )
        }
        state.userDuties = newDuties
        const NewArr = []
        newDuties.map((item) => {
          state.selectedCalendars.map((cal) => {
            if (item.extendedProps.calendar === cal) {
              NewArr.push(item)
            }
          })
        })
        state.filteredUserEvents = NewArr
        // state.filteredUserEvents = newDuties 
      })
      .addCase(fetchDutyTypes.fulfilled, (state, action) => {
        state.dutyTypes = action.payload
        if (state.selectedCalendars.length > 0) {
          state.selectedCalendars = state.selectedCalendars
        } else {
          const Types = []
          action.payload.optionValues.map((val) => Types.push(val._id)
            // Types.push(item)
          )
          state.selectedCalendars = Types
        }
      })
      .addCase(updateFilter.fulfilled, (state, action) => {
        if (state.selectedCalendars.includes(action.payload)) {
          const newArray = state.selectedCalendars.splice(
            state.selectedCalendars.indexOf(action.payload),
            1
          )
          state.filteredEvents = state.events.filter(
            (event) => event.extendedProps.calendar !== newArray[0]
          )
          state.filteredUserEvents = state.userDuties.filter(
            (event) => event.extendedProps.calendar !== newArray[0]
          )
        } else {
          state.selectedCalendars.push(action.payload)
          state.filteredEvents = [
            ...state.filteredEvents,
            ...state.events.filter(
              (event) => event.extendedProps.calendar === action.payload
            )
          ]
          state.filteredUserEvents = [
            ...state.filteredUserEvents,
            ...state.userDuties.filter(
              (event) => event.extendedProps.calendar === action.payload
            )
          ]
        }
      })
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        const value = action.payload
        let selected = []
        if (value === true) {
          state.dutyTypes.optionValues.map((val) => selected.push(val._id)
          )
          selected = selected
          state.filteredEvents = state.events
          state.filteredUserEvents = state.userDuties
        } else {
          selected = []
          state.filteredEvents = []
          state.filteredUserEvents = []
        }
        state.selectedCalendars = selected
      })
      .addCase(showLoading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export const { selectEvent } = appDutiesSlice.actions

export default appDutiesSlice.reducer 

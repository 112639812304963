
const ToastContent = (props) => {
  return (
    <div className='d-flex'>
      <div className='d-flex flex-column'>
        <span>{props.message}</span>
      </div>
    </div>
  )
  
}
export default ToastContent

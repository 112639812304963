// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '../../../../auth/jwt/useJwt'
import * as url from '../../../../helpers/url_helper'
import toast from 'react-hot-toast'
import ToastContent from '../../../ui-elements/toast'

// ** Axios Imports
import axios from 'axios'

// ** for Loading Spinner
export const showLoading = createAsyncThunk('appUsers/showLoading', async status => {
  return status
})
export const getAllData = createAsyncThunk('appUsers/getAllData', async (id, { dispatch }) => {
  try {
    const response = await useJwt.get(`${url.GET_USERS}${id !== undefined ? id : ""}`);
    if (response) {
      dispatch(showLoading(false));
    }
    return response.payload;
  } catch (error) {
    dispatch(showLoading(false));
    return []
  }
});

export const getAllSolicters = createAsyncThunk('appUsers/getAllSolicters', async (isDuty, { dispatch }) => {
  try {
    const response = await useJwt.get(`${url.GET_USERS}&isDuty=${true}`);
    if (response) {
      dispatch(showLoading(false));
    }
    return response.payload;
  } catch (error) {
    dispatch(showLoading(false));
    return []
  }
});


export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getRefreshToken = createAsyncThunk('appUsers/getData', async () => {
  const response = await axios.get('/auth/refreshToken')
  return response
})

export const getUser = createAsyncThunk('appUsers/getUser', async (id, { dispatch }) => {
  const response = await useJwt.get(`${url.GET_USER}${id !== undefined ? id : ""}`)
  if (response) {
    dispatch(showLoading(false))
  }
  return response.payload
})

export const resendWelcomeEmail = createAsyncThunk('appUsers/resendWelcomeEmail', async (id) => {
  const response = await useJwt.post(`${url.RESEND_WELCOME_EMAIL}${id !== undefined ? id : ""}`)
  return response.payload
})

export const getUserOwnProfile = createAsyncThunk('appUsers/getUserOwnProfile', async (id, { dispatch }) => {
  const response = await useJwt.get(`${url.GET_USER_OWN_PROFILE}`)
  if (response) {
    dispatch(showLoading(false))
  }
  return response.payload
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch }) => {
  await useJwt.post(url.REGISTER_USER, user)
    .then(async () => {
      toast.success(() => (
        <ToastContent message="User Created Successfully" />
      ), {
        position: "top-center",
        reverseOrder: false
      })
      await dispatch(getAllData())
      dispatch(showLoading(false))
    })
    .catch((res) => {
      dispatch(showLoading(false))
      toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
        position: "top-center",
        reverseOrder: false
      })

    })

  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch }) => {
  await useJwt.del(`${url.DELETE_USER}${id}`)
    .then(async () => {
      toast.success(() => (
        <ToastContent message="User has been Deleted" />
      ), {
        position: "top-center",
        reverseOrder: false
      })
      await dispatch(getAllData())
      dispatch(showLoading(false))
    })
    .catch((res) => {
      dispatch(showLoading(false))
      toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
        position: "top-center",
        reverseOrder: false
      })

    })

  return id
})

export const updateUser = createAsyncThunk('appUsers/updateUser', async (form, { dispatch }) => {
  await useJwt.put(url.UPDATE_USER, form)
    .then(async () => {
      // window.location.reload(true)
      toast.success(() => (
        <ToastContent message="User Updated Successfully" />
      ), {
        position: "top-center",
        reverseOrder: false
      })
      // await dispatch(getAllData())
      await dispatch(getUser(form._id))
      dispatch(showLoading(false))
    })
    .catch((res) => {
      dispatch(showLoading(false))
      toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
        position: "top-center",
        reverseOrder: false
      })

    })
})


export const registerOPTMethod = createAsyncThunk('appUsers/registerOPTMethod', async (form, { dispatch }) => {
  await useJwt.post(url.REGISTER_OTP_METHOD, form)
    .then(async () => {
      toast.success(() => (
        <ToastContent message="OTP Method added Successfully" />
      ), {
        position: "top-center",
        reverseOrder: false
      })
      await dispatch(getAllData())
      await dispatch(getUser(form._id))
      dispatch(showLoading(false))
    })
    .catch((res) => {
      dispatch(showLoading(false))
      toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
        position: "top-center",
        reverseOrder: false
      })

    })

  return form
})

export const forgotPassword = createAsyncThunk('appUsers/forgotPassword', async (email, { dispatch }) => {
  const response = await useJwt.post(url.FORGOT_PASSWORD, { email })
  if (response) {
    dispatch(showLoading(false))
  }
  return email
})

export const getOTPMethods = createAsyncThunk('appUsers/getOTPMethods', async (email, { dispatch }) => {
  const response = await useJwt.get(`${url.GET_OTP_METHODS}${email}`)
  if (response) {
    dispatch(showLoading(false))
  }

  return response.payload
})

export const getAllRoles = createAsyncThunk('appUsers/getAllRoles', async (id) => {
  const response = await useJwt.get(`${url.GET_ALL_ROLES}${id !== undefined ? id : ""}`)
  return response.payload
})

export const updateGroups = createAsyncThunk('appUsers/updateGroups', async (form, { dispatch }) => {

  await useJwt.put(url.UPDATE_USER_GROUPS, form)
    .then(async () => {
      toast.success(() => (
        <ToastContent message="Groups Updated Successfully" />
      ), {
        position: "top-center",
        reverseOrder: false
      })
      await dispatch(getAllData())
      await dispatch(getUser(form._id))
      dispatch(showLoading(false))
    })
    .catch((res) => {
      dispatch(showLoading(false))
      toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
        position: "top-center",
        reverseOrder: false
      })

    })

  return form
})

export const getUserDesignations = createAsyncThunk('appUsers/getUserDesignations', async () => {
  const response = await useJwt.get(url.GET_USER_DESIGNATIONS)
  return response.payload
})

export const addWorkFlowActions = createAsyncThunk('appUsers/addWorkFlowActions', async (form, { dispatch }) => {

  await useJwt.put(url.ADD_WORKFLOW_ACTIONS, form)
    .then(async () => {

      toast.success(() => (
        <ToastContent message="WorkFlow Updated Successfully" />
      ), {
        position: "top-center",
        reverseOrder: false
      })
      await dispatch(getUser(form.userID))
      dispatch(showLoading(false))
    })
    .catch((res) => {
      dispatch(showLoading(false))
      toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
        position: "top-center",
        reverseOrder: false
      })

    })

  return form
})

export const deleteWorkflowAction = createAsyncThunk('appUsers/deleteWorkflowAction', async (form, { dispatch }) => {
  await useJwt.del(`${url.DELETE_WORKFLOW_ACTION}`, form)
    .then(async () => {
      toast.success(() => (
        <ToastContent message="Workflow Action has been Deleted" />
      ), {
        position: "top-center",
        reverseOrder: false
      })
      await dispatch(getUser(form.userID))
      dispatch(showLoading(false))
    })
    .catch((res) => {
      dispatch(showLoading(false))
      toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
        position: "top-center",
        reverseOrder: false
      })

    })

  return form
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    solicters: [],
    selectedUser: null,
    isLoading: false,
    allRoles: [],
    OTPMethods: [],
    desingations: [],
    userProfile: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getAllSolicters.fulfilled, (state, action) => {
        state.solicters = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(showLoading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.allRoles = action.payload
      })
      .addCase(getOTPMethods.fulfilled, (state, action) => {
        state.OTPMethods = action.payload
      })
      .addCase(getUserOwnProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })
      .addCase(getUserDesignations.fulfilled, (state, action) => {
        const Types = []
        action.payload.options.map((item) => (
          item.optionValues.map((val) => (
            Types.push({
              label: val.value,
              value: val._id
            })
          )
          )
        )
        )
        state.desingations = Types
      })
  }
})

export default appUsersSlice.reducer

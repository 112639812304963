
//Auth
//Get OTP Methods
export const GET_OTP_METHODS = "/auth/getOTPMethods?email="


export const GET_USER_NOTIFICATIONS = "/users/getUserNotifications"
// Register Program User
export const REGISTER_USER = "/users/registerUser"
// Get All Data
export const GET_USERS = "/users/getUsersByRole?roleid="
// Get SIingle User
export const GET_USER = "/users/getUserProfile?userid="
// Get SIingle User
export const GET_USER_OWN_PROFILE = "/profile/getUserOwnProfile"
// Add User
export const Add_USER = "/users"
// Delete User
export const DELETE_USER = "/users/user?userid="
// Request OTP
export const UPDATE_USER = "/users/updateUser"
// Update Profile
export const UPDATE_PROFILE = "/profile"
// Register OTP Method
export const REGISTER_OTP_METHOD = "/users/registerOTPMethod"
// Update Password
export const UPDATE_PASSWORD = "/password-update"
// Forgot Password
export const FORGOT_PASSWORD = "/auth/forgotPassword"
// Reset Password
export const RESET_PASSWORD = "/password-reset"
// Update User Groups
export const UPDATE_USER_GROUPS = "/users/updateGroups"
// Get User Designations Types
export const GET_USER_DESIGNATIONS = "/users/getDesignationsTypes"
// Add Workflow action
export const ADD_WORKFLOW_ACTIONS = "/users/addWorkFlowAction"
// Delete Workflow action
export const DELETE_WORKFLOW_ACTION = "/users/deleteWorkFlowAction"
// Resend Welcome Email
export const RESEND_WELCOME_EMAIL = "/users/resendRegistrationDetails?userid="

//systemOperations
// Roles
export const GET_ALL_ROLES = "/systemOperations/roles/getAllRoles?roleid="
export const ADD_ROLE = "/systemOperations/roles/createRole"
export const UPDATE_ROLE = "/systemOperations/roles/updateRole"
export const DELETE_ROLE = "/systemOperations/roles/deleteRole?roleid="

// Permissions
export const GET_All_PERMISSIONS = "/systemOperations/permissions/getAllPermissions"
export const GET_USER_PERMISSIONS = "/systemOperations/permissions/getUserPermissionsByUserID?userid="
export const ADD_PERMISSION = "/systemOperations/permissions/createPermission"
export const UPDATE_PERMISSION = "/systemOperations/permissions/updatePermission"

// Modules
export const GET_MODULES = "/systemOperations/modules/getAllModules"
export const ADD_MODULE = "/systemOperations/modules/createModule"

// Groups
export const GET_GROUPS = "/systemOperations/groups/getGroups"
export const ADD_GROUP = "/systemOperations/groups/createGroup"
export const DELETE_GROUP = "/systemOperations/groups/deleteGroup?groupid="
export const UPDATE_GROUP = "/systemOperations/groups/updateGroup"
export const GET_GROUP_TYPES = "/systemOperations/groups/getGroupsTypes"

// Options
export const GET_OPTION_GROUPS = "/systemOperations/options/getOptionGroup"
export const ADD_OPTION_GROUP = "/systemOperations/options/createOptionGroup"
export const UPDATE_OPTION_GROUP = "/systemOperations/options/updateOptionGroup"

//Settings
export const GET_ALL_SETTINGS = "/systemOperations/settings/getAllSettings"
export const ARCHIVE_OPTION_VALUES = "/systemOperations/settings/archiveSettingOptionValues"
export const CREATE_SETTING_OPTION = "/systemOperations/settings/createSettingOption"
export const CREATE_NEW_SETTING = "/systemOperations/settings/createSetting"
export const UPDATE_SETTING_OPTION = "/systemOperations/settings/updateSettingOption"
export const GET_HEARING_TYPES = "/hearing/getHearingTypes"
export const GET_Matter_TYPES = "/systemOperations/settings/getMatterTypes"
export const GET_DISABILITIES = "/systemOperations/settings/getDisabilities"
export const GET_ETHNICITY_OPTIONS = "/systemOperations/settings/getEhnicityOptions"
export const ADD_NEW_HEARING = "/hearing/addNewHearing"
export const ADD_NEW_KEY_DATE = "/hearing/addKeyDate"
export const UPDATE_KEY_DATE = "/hearing/updateKeyDate"
export const DELETE_KEY_DATE = '/hearing/deleteKeyDate'
export const GET_KEY_DATES = "/hearing/getKeyDates"
export const ADD_NEW_DIRECTION = "/hearing/addDirection"
export const GET_DIRECTIONS = "/hearing/getDirections"
export const DELETE_DIRECTION = '/hearing/deleteDirection'
export const UPDATE_DIRECTION = "/hearing/updateDirection"
export const CREATE_NEW_OPTION_VALUE = "/systemOperations/settings/createSettingOptionValue"
export const GET_LLA_CODES = "/systemOperations/settings/getLlaCodesOptions?filter="

export const UPDATE_SETTING_OPTION_VALUE = "/systemOperations/settings/updateSettingOptionValue"


//Forms
export const CREATE_FORM_TYPE = "/forms/settings/formType/createFormType"
export const GET_FORM_TYPES = "/forms/settings/formType/getFormType"
export const GET_SINGLE_FORM_TYPE = "/forms/settings/formType/getFormType?formTypeID="
export const UPDATE_FORM_VERSION = "/forms/settings/formType/addNewFormTypeVersion"
export const DELETE_FORM_TYPE = "/forms/settings/formType/deleteFormType"
export const GET_FORM_CATEGORIES = "/forms/settings/formType/getFormCategories"
export const GET_USER_FORM_LIST_VIEW = "/forms/actions/userActions/getUsersListView?formTypeID="
export const GET_AUTH_FORM_LIST_VIEW = "/forms/actions/userActions/getAuthorizersListView?formTypeID="
export const GET_USER_SUBMISSION_FORM_BY_ID = "/forms/actions/userActions/getUsersFormByID?formID="
export const GET_AUTH_SUBMISSION_FORM_BY_ID = "/forms/actions/userActions/getAuthorizersSingleFormByID?formID="
export const ADD_COMMENT_TO_FROM = "/forms/actions/userActions/addComment"
export const CHECK_AVAILABLE_PREFIXES = "/forms/settings/formType/checkAvailablePrefix"
// export const UPLOAD_DOCUMENT = "/documents/createUploadRequest"  

//Form Actions
export const SUBMIT_FORM = "/forms/actions/userActions/createSubmitForm"
export const ADD_FORM_COMMENT = "/forms/actions/userActions/addComment"
export const NO_FORM_SUBMISSION = "/forms/actions/userActions/newFormSubmission"
export const GET_USER_FORM_TYPES = "/forms/actions/userActions/getUserFormTypes"
export const GET_AUTH_FORM_TYPES = "/forms/actions/userActions/getAuthorizerFormTypes"
export const GET_USER_FORM_SUBMISSION = "/forms/actions/userActions/getFormSubmissions"
export const GET_FORM_SUBMISSION_BY_TYPE = "/forms/actions/userActions/getFormSubmissionsByFormType?formTypeID="
export const GET_FORM_AUTHORIZERS_BY_TYPE = "/forms/actions/userActions/getAuthorizersFormsByFormType"

export const UPLOAD_DOCUMENT = "/forms/actions/userActions/createNewFormDocument"
export const DELETE_FORM_DOCUMENT = "/forms/actions/userActions/deleteDocument"

//WorkFlow
export const CREATE_WORKFLOW = "/workflow/createWorkFlow"
export const GET_WORKFLOW = "/workflow/getWorkFlows"
export const GET_WORKFLOW_ACTIONS = "/workflow/getWorkFlowActions"
export const GET_AVIALABLE_TRANSITIONS = "/workflow/getAvailableTransitions"
export const GET_AVAILABLE_TRANSITIONS_NEW = "/workflow/transitions/getAvailableTransitions"
export const GET_WORKFLOW_STATE_TASKS = "/workflow/getWorkFlowStateTasks"
export const UPDATE_WORKFLOW = "/workflow/updateWorkFlowByID"


//GovInstitute
export const GET_GOV_INSTITUE = "/govInsistute/actions/GetAllGovInstitute"
export const GET_GOV_INSTITUE_TYPES = "/govInsistute/actions/GetGovInsistuteTypes"
export const ADD_GOV_INSTITUE = "/govInsistute/actions/createInstitute"
export const GET_INSTITUE_DUTIES_BY_DATES = "/duty/getInstituteDutiesByDate"
export const GET_INSTITUE_ACTIVITIES = "/paycard/getInstituteActivities"
export const GET_INSTITUTE_CONTACTS = "/govInsistute/actions/getInstituteContacts"
export const ADD_INSTITUTE_CONTACTS = "/govInsistute/actions/addInstituteContacts"
export const UPDATE_GOV_INSTITUE = "/govInsistute/actions/updateInstitute"
export const UPDATE_GOV_INSTITUE_CONTACT = "/govInsistute/actions/updateInstituteContact"
export const ADD_POLICE_STATION_SCHEMES = "/govInsistute/actions/registerPoliceStationScheme"
export const GET_POLICE_STATION_SCHEMES = "/govInsistute/actions/getPoliceStationSchemes"
export const ADD_CURRENT_RATE_POLICE_STATION_SCHEMES = "/govInsistute/actions/addCurrentRateToPoliceStationScheme"
export const ADD_FUTURE_RATE_POLICE_STATION_SCHEMES = "/govInsistute/actions/addFutureRateToPoliceStationScheme"


// ActivityCards
export const GET_ALL_ACTIVITYCARDS = "/govInsistute/actions/GeAllActivityCards"
export const CREATE_ACTIVITYCARD = "/govInsistute/actions/createPayableActivityCard"
export const UPDATE_ACTIVITYCARD_STATUS = "/govInsistute/actions/UpdateActivityCardIsActive"
export const GET_CURRENCY_TYPES = "/govInsistute/actions/getCurrencyTypes"
export const GET_CHARGE_TYPES = "/govInsistute/actions/getChargeTypes"
export const GET_ACTIVITY_TYPES = "/govInsistute/actions/getActivityTypes"

//Calender
export const GET_CALENDER_EVENT = "/calender/getCalenderEvent"
export const CREATE_CALENDER_EVENT = "/calender/createCalenderEvent"
export const GET_EVENT_TYPES = "/calender/getEventTypes"
export const GET_CALENDER_TYPES = "/calender/getCalenderTypes"
export const GET_LOCATION_TYPES = "/calender/getLocationTypes"

// Do Transition
export const DO_TRANSITION = "/workflow/transitions/doTransition"
export const CREATE_DOCUMENT = "/forms/actions/userActions/createNewFormDocument"
export const VIEW_DOCUMENT = "/forms/actions/userActions/downloadDocument"
export const CREATE_MULTI_DOCUMENT = "/forms/actions/userActions/createMultiFormDocuments"
export const DELETE_FORM = "/forms/settings/formType/deleteForm"

// Clients
export const GET_ALL_CLEINTS = "/users/clients/findAllClients"
export const ADD_CLIENT = "/users/clients/registerClient"
export const UPDATE_CLIENT_INFO = "/users/clients/updateClient"
export const ADD_CLIENT_ADDRESS = "/users/clients/addClientAddress"
export const UPDATE_CLIENT_ADDRESS = "/users/clients/updateClientAddress"
export const ADD_ELECTRONIC_ADDRESS = "/users/clients/addClientElectronicAddress"
export const UPDATE_ELECTRONIC_ADDRESS = "/users/clients/updateClientElectronicAddress"

// Duties
export const GET_ALL_DUTIES = "/duty/getAllDuties"
export const GET_DUTY_TYPES = "/duty/getDutyTypes"
export const GET_OWN_DUTIES = "/duty/getOwnDuties"
export const GET_USER_DUTIES = "/duty/getDutiesByUserID"
export const CREATE_OWN_DUTY = "/duty/createOwnDuty"
export const CREATE_DUTY = "/duty/createDuty"
export const PAYCARD_DO_STATE_TASK = "/paycard/do-state-task"
export const CREATE_BULK_DUTY = "/duty/createBulkDuties"

// Dashaboard
export const GET_DASHBOARD_FORMS_REPOT = "/dashboard/getFormsTrackerReport"
export const GET_DASHBOARD_AUTHORIZER_FORMS_REPOT = "/dashboard/getAuthorizerFormsTrackerReport"
export const GET_DASHBOARD_SUBMITTER_FORMS_REPOT = "/dashboard/getSubmitterFormsTrackerReport"
export const GET_DASHBOARD_MONTHLY_SUBMISSION = "/dashboard/getMonthlyFormsSubmissions"
export const GET_DASHBOARD_AUTHORIZER_MONTHLY_SUBMISSION = "/dashboard/getAuthorizerMonthlyFormsSubmissions"
export const GET_DASHBOARD_SUBMITTER_MONTHLY_SUBMISSION = "/dashboard/getSubmitterMonthlyFormsSubmissions"
export const GET_DASHBOARD_SUBMISSIONS = "/dashboard/getSubmissionsReport"
export const GET_DASHBOARD_AUTHORIZER_SUBMISSIONS = "/dashboard/getAuthorizerSubmissionsReport"
export const GET_DASHBOARD_SUBMITTER_SUBMISSIONS = "/dashboard/getSubmitterSubmissionsReport"

//Reports 
export const GET_ADMIN_DUTY_PAYMENTS_REPORT = "/reports/admin/adminDutyPaymentReport"
export const GET_USER_DUTY_PAYMENTS_REPORT = "/reports/user/userDutyPaymentReport"

// PayCards
export const CREATE_USER_POLICE_STATION_PAY_CARD = "/paycard/createUserPoilceStationPayCard"
export const CREATE_GROUP_POLICE_STATION_PAY_CARD = "/paycard/createGroupPoilceStationPayCard"
export const GET_USER_POLICE_STATION_PAY_CARD = "/paycard/getUserPoilceStationPayCards?UserID"
export const GET_GROUP_POLICE_STATION_PAY_CARD = "/paycard/getGroupPoilceStationPayCards?GroupID="

//Payment Requests
export const CREATE_DRAFT_PAYMENT_REQUEST = "/duty/payments/createDraftPaymentRequest"
export const GET_USER_PAYMENT_REQUEST = "/duty/payments/getUserPaymentRequests"
export const GET_ADMIN_PAYMENT_REQUEST = "/duty/payments/getAdminPaymentRequests"
export const CREATE_DOCUMENT_PAYMENT_REQUEST = "/duty/payments/createMultiPRDocuments"
export const CREATE_PAYMENT_REQUEST = "/duty/payments/submitPaymentRequest"
export const GET_PAYMENT_REQUEST_DETAIL = "/duty/payments/getUserPaymentRequestDetail?requestID="
export const ADD_COMMENT_TO_PAYMENT_REQUEST = "/duty/payments/addCommentToPaymentRequest"
export const DOWNLAOD_OWN_PAYMENT_REQUEST_DOCUMENTS = "/duty/payments/downloadOwnPaymentRequestDocument?id="
export const GET_ADMIN_PAYMENT_REQUEST_DETAIL = "/duty/payments/getAdminPaymentRequestDetail?requestID="
export const APPROVE_PAYMENT_REQUEST = "/duty/payments/approvePaymentRequest"
export const REJECT_PAYMENT_REQUEST = "/duty/payments/rejectPaymentRequest"
// ** Reducers Imports
import navbar from "./navbar"
import layout from "./layout"
import auth from "./authentication"
import users from "../views/apps/user/store/index"
import systemOperations from "../views/systemOperations/store"
import forms from "../views/formBuilder/store"
import Workflow from "../views/workflow/store"
import calendar from "../views/calendar/store"
import GovInstitute from "../views/govInstitute/store"
import clients from "../views/clients/store"
import duties from "../views/duties/store"
import dashboard from "../views/dashboard/store"
import keyDates from "../views/keyDates/store"
import reports from '../views/reports/store'
import directions from '../views/directions/store'
import dateViews from '../views/dateViews/store'
import payCards from '../views/payCards/store'
import paymentRequests from '../views/paymentRequests/store'
const rootReducer = {
  auth,
  users,
  navbar,
  layout,
  systemOperations,
  forms,
  Workflow,
  calendar,
  GovInstitute,
  clients,
  duties,
  dashboard,
  keyDates,
  directions,
  reports,
  dateViews,
  payCards,
  paymentRequests
}

export default rootReducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import useJwt from "../../../auth/jwt/useJwt"
import * as url from "../../../helpers/url_helper"
import toast from "react-hot-toast"
import ToastContent from "../../ui-elements/toast/index"

// ** for Loading Spinner
export const showLoading = createAsyncThunk(
  "systemOperations/showLoading",
  async (status) => {
    return status
  }
)

// ** Roles
export const getRoles = createAsyncThunk(
  "systemOperations/getRoles",
  async (id, { dispatch }) => {
    const response = await useJwt.get(
      `${url.GET_ALL_ROLES}${id !== undefined ? id : ""}`
    )
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const addRole = createAsyncThunk(
  "systemOperations/addRole",
  async (role, { dispatch }) => {
    const response = await useJwt.post(url.ADD_ROLE, role)
    if (response) {
      // toast.success(
      //   <ToastContent message="Role Created Successfully" />,
      //   { transition: Slide, autoClose: 3000 }
      // )
      await dispatch(getRoles())
      dispatch(showLoading(false))
    }
    return role
  }
)

export const updateRole = createAsyncThunk(
  "systemOperations/updateRole",
  async (role, { dispatch }) => {
    await useJwt
      .put(url.UPDATE_ROLE, role)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Role Updated Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(getRoles())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })
    return role
  }

)

export const deleteRole = createAsyncThunk(
  "systemOperations/deleteRole",
  async (id, { dispatch }) => {
    const response = await useJwt.del(
      `${url.DELETE_ROLE}${id !== undefined ? id : ""}`
    )
    if (response) {
      await dispatch(getRoles())
      dispatch(showLoading(false))
    }
    return id
  }
)

// ** Permissions
export const getAllPermissions = createAsyncThunk(
  "systemOperations/Allpermissions",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_All_PERMISSIONS}`)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const getPermissions = createAsyncThunk(
  "systemOperations/permissions",
  async (id, { dispatch }) => {
    const response = await useJwt.get(
      `${url.GET_USER_PERMISSIONS}${id !== undefined ? id : ""}`
    )
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const addPermission = createAsyncThunk(
  "systemOperations/addPermission",
  async (permission, { dispatch }) => {
    await useJwt
      .post(url.ADD_PERMISSION, permission)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Permission Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(getAllPermissions())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })
    return module
  }
)

export const updatePermission = createAsyncThunk(
  "systemOperations/updatePermission",
  async (permission, { dispatch }) => {
    await useJwt
      .put(url.UPDATE_PERMISSION, permission)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Permission Updated Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(getAllPermissions())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })
    return permission
  }

)

// ** Modules
export const getModules = createAsyncThunk(
  "systemOperations/moudles",
  async (id, { dispatch }) => {
    const response = await useJwt.get(url.GET_MODULES)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const addModule = createAsyncThunk(
  "systemOperations/addModules",
  async (module, { dispatch }) => {
    const response = await useJwt.post(url.ADD_MODULE, module)
    if (response) {
      // toast.success(
      //   <ToastContent message="Module Created Successfully" />,
      //   { transition: Slide, autoClose: 3000 }
      // )
      await dispatch(getModules())
      dispatch(showLoading(false))
    }
    return module
  }
)

// ** Groups
export const getGroups = createAsyncThunk(
  "systemOperations/groups",
  async (id, { dispatch }) => {
    const response = await useJwt.get(
      `${url.GET_GROUPS}${id !== undefined ? `?groupid=${id}` : ""}`
    )
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const addGroup = createAsyncThunk(
  "systemOperations/addGroup",
  async (group, { dispatch }) => {
    const response = await useJwt.post(url.ADD_GROUP, group)
    if (response) {
      // toast.success(
      //   <ToastContent message="Group Created Successfully" />,
      //   { transition: Slide, autoClose: 3000 }
      // )
      await dispatch(getGroups())
      dispatch(showLoading(false))
    }
    return group
  }
)
export const updateGroup = createAsyncThunk(
  "systemOperations/updateGroup",
  async (group, { dispatch }) => {
    await useJwt.put(url.UPDATE_GROUP, group)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Group Updated Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(getGroups())
        dispatch(showLoading(false))
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })
    return module
  }
)
// export const updateGroup = createAsyncThunk(
//   "systemOperations/updateGroup",
//   async (group, { dispatch }) => {
//     const response = await useJwt.put(url.UPDATE_GROUP, group) 
//     if (response) {
//       toast.success(
//         () => <ToastContent message="Group added Successfully" />,
//         {
//           position: "top-center",
//           reverseOrder: false
//         }
//       ) 
//       await dispatch(getGroups()) 
//       dispatch(showLoading(false)) 
//     }
//     return group 
//   }
// ) 

export const deleteGroup = createAsyncThunk(
  "systemOperations/deleteGroup",
  async (id, { dispatch }) => {
    const response = await useJwt.del(
      `${url.DELETE_GROUP}${id !== undefined ? id : ""}`
    )
    if (response) {
      await dispatch(getGroups())
      dispatch(showLoading(false))
    }
    return id
  }
)

export const getGroupTypes = createAsyncThunk(
  "systemOperations/groupTypes",
  async (id, { dispatch }) => {
    const response = await useJwt.get(url.GET_GROUP_TYPES)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

// ** Options
export const getOptionGroups = createAsyncThunk(
  "systemOperations/optionGroups",
  async (groupid, { optionID, dispatch }) => {
    const response = await useJwt.get(
      `${url.GET_OPTION_GROUPS}?OptionGroupID=${groupid !== undefined ? groupid : ""
      }&OptionValueID=${optionID !== undefined ? optionID : ""}`
    )
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const addOptionGroup = createAsyncThunk(
  "systemOperations/addOptionGroup",
  async (group, { dispatch }) => {
    const response = await useJwt.post(url.ADD_OPTION_GROUP, group)
    if (response) {
      // toast.success(
      //   <ToastContent message="Group Created Successfully" />,
      //   { transition: Slide, autoClose: 3000 }
      // )
      await dispatch(getOptionGroups())
      dispatch(showLoading(false))
    }
    return group
  }
)

export const updateOptionGroup = createAsyncThunk(
  "systemOperations/updateOptionGroup",
  async (group, { dispatch }) => {
    const response = await useJwt.put(url.UPDATE_OPTION_GROUP, group)
    if (response) {
      // toast.success(
      //   <ToastContent message="Group Updated Successfully" />,
      //   { transition: Slide, autoClose: 3000 }
      // )
      dispatch(showLoading(false))
    }
    return group
  }
)

// ** Gov Institutes
export const getGovInstitute = createAsyncThunk(
  "systemOperations/govInstitute",
  async (id, { dispatch }) => {
    const response = await useJwt.get(url.GET_GOV_INSTITUE)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

// ** Settings
export const getAllSettings = createAsyncThunk(
  "systemOperations/getAllSettings",
  async (id, { dispatch }) => {
    const response = await useJwt.get(url.GET_ALL_SETTINGS)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const archiveOptionValues = createAsyncThunk("systemOperations/archiveOptionValues", async (optionValue, { dispatch }) => {
  await useJwt.post(url.ARCHIVE_OPTION_VALUES, optionValue)
    .then(async () => {
      toast.success(() => (
        <ToastContent message="Option Value Archived Successfully" />
      ), {
        position: "top-center",
        reverseOrder: false
      })
      await dispatch(getAllSettings())
      dispatch(showLoading(false))
    })
    .catch((res) => {
      dispatch(showLoading(false))
      toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
        position: "top-center",
        reverseOrder: false
      })

    })

  return optionValue
})

export const createSettingOption = createAsyncThunk("systemOperations/createSettingOption", async (optionValue, { dispatch }) => {
  await useJwt.post(url.CREATE_SETTING_OPTION, optionValue)
    .then(async () => {
      toast.success(() => (
        <ToastContent message="Option Added Successfully" />
      ), {
        position: "top-center",
        reverseOrder: false
      })
      await dispatch(getAllSettings())
      dispatch(showLoading(false))
    })
    .catch((res) => {
      dispatch(showLoading(false))
      toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
        position: "top-center",
        reverseOrder: false
      })

    })

  return optionValue
})

export const createSetting = createAsyncThunk("systemOperations/createSetting", async (optionValue, { dispatch }) => {
  await useJwt.post(url.CREATE_NEW_SETTING, optionValue)
    .then(async () => {
      toast.success(() => (
        <ToastContent message="System Setting Added Successfully" />
      ), {
        position: "top-center",
        reverseOrder: false
      })
      await dispatch(getAllSettings())
      dispatch(showLoading(false))
    })
    .catch((res) => {
      dispatch(showLoading(false))
      toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
        position: "top-center",
        reverseOrder: false
      })

    })

  return optionValue
})

export const updateOption = createAsyncThunk("systemOperations/updateOption", async (optionValue, { dispatch }) => {
  await useJwt.put(url.UPDATE_SETTING_OPTION, optionValue)
    .then(async () => {
      toast.success(() => (
        <ToastContent message="Option Updated Successfully" />
      ), {
        position: "top-center",
        reverseOrder: false
      })
      await dispatch(getAllSettings())
      dispatch(showLoading(false))
    })
    .catch((res) => {
      dispatch(showLoading(false))
      toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
        position: "top-center",
        reverseOrder: false
      })

    })

  return optionValue
})

export const addNewOptionValue = createAsyncThunk(
  "systemOperations/addNewOptionValue",
  async (data, { dispatch }) => {
    await useJwt.post(url.CREATE_NEW_OPTION_VALUE, data)
      .then(async () => {
        toast.success(() => (
          <ToastContent message="Option Added Successfully" />
        ), {
          position: "top-center",
          reverseOrder: false
        })
        await dispatch(getAllSettings())
        await dispatch(getCrimeSettings())
        dispatch(showLoading(false))
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })
    return data
  }
)


// ** Settings
export const getCrimeSettings = createAsyncThunk(
  "systemOperations/getCrimeSettings",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_ALL_SETTINGS}?module=crime`)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)


export const updateOptionValue = createAsyncThunk("systemOperations/updateValueOption", async (optionValue, { dispatch }) => {
  await useJwt.put(url.UPDATE_SETTING_OPTION_VALUE, optionValue)
    .then(async () => {
      toast.success(() => (
        <ToastContent message="Option Updated Successfully" />
      ), {
        position: "top-center",
        reverseOrder: false
      })
      await dispatch(getAllSettings())
      await dispatch(getCrimeSettings())
      dispatch(showLoading(false))
    })
    .catch((res) => {
      dispatch(showLoading(false))
      toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
        position: "top-center",
        reverseOrder: false
      })

    })

  return optionValue
})


export const getLLACodes = createAsyncThunk(
  "appForms/getLLACodes",
  async (code, { dispatch }) => {
    const response = await useJwt
      .get(`${url.GET_LLA_CODES}${code}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        if (code === 'Mental Health Codes') {
          return { mentalHealth: res.payload }
        } else if (code === 'Matter Types Police Station') {
          return { materTypes: res.payload }
        } else if (code === 'Gender') {
          return { gender: res.payload }
        } else if (code === 'Ethnicity') {
          return { ethnicity: res.payload }
        } else if (code === 'Interpreter Languages') {
          return { interpreterLanguages: res.payload }
        } else if (code === 'Offences') {
          return { offences: res.payload }
        } else if (code === 'Transition Tasks') {
          return { transitiontasks: res.payload }
        } else {
          return res
        }
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response
  }
)



export const systemOperationsSlice = createSlice({
  name: "systemOperations",
  initialState: {
    userRoles: [],
    allPermissions: [],
    userPermissions: [],
    modules: [],
    groups: [],
    groupTypes: [],
    optionGroups: [],
    isLoading: false,
    govInstitute: [],
    settings: [],
    mentalHealthCodes: [],
    matterTypesCodes: [],
    genderCodes: [],
    ethnicityCodes: [],
    interpreterLanguages: [],
    offences: [],
    crimeSettings: [],
    transitiontasks: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.fulfilled, (state, action) => {
        state.userRoles = action.payload
      })
      .addCase(getAllPermissions.fulfilled, (state, action) => {
        state.allPermissions = action.payload
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.userPermissions = action.payload
      })
      .addCase(getModules.fulfilled, (state, action) => {
        state.modules = action.payload
      })
      .addCase(getGroups.fulfilled, (state, action) => {
        state.groups = action.payload
      })
      .addCase(getGroupTypes.fulfilled, (state, action) => {
        state.groupTypes = action.payload
      })
      .addCase(getOptionGroups.fulfilled, (state, action) => {
        state.optionGroups = action.payload
      })
      .addCase(showLoading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
      .addCase(getGovInstitute.fulfilled, (state, action) => {
        state.govInstitute = action.payload
      })
      .addCase(getAllSettings.fulfilled, (state, action) => {
        state.settings = action.payload
      })
      .addCase(getLLACodes.fulfilled, (state, action) => {
        if (action.payload && action.payload.mentalHealth) {
          state.mentalHealthCodes = action.payload.mentalHealth
        } else if (action.payload && action.payload.materTypes) {
          state.matterTypesCodes = action.payload.materTypes
        } else if (action.payload && action.payload.gender) {
          state.genderCodes = action.payload.gender
        } else if (action.payload && action.payload.ethnicity) {
          state.ethnicityCodes = action.payload.ethnicity
        } else if (action.payload && action.payload.interpreterLanguages) {
          state.interpreterLanguages = action.payload.interpreterLanguages
        } else if (action.payload && action.payload.offences) {
          state.offences = action.payload.offences
        } else if (action.payload && action.payload.transitiontasks) {
          state.transitiontasks = action.payload.transitiontasks
        }
      })
      .addCase(getCrimeSettings.fulfilled, (state, action) => {
        state.crimeSettings = action.payload
      })
  }
})

export default systemOperationsSlice.reducer 

// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import useJwt from "../../../auth/jwt/useJwt"
import * as url from "../../../helpers/url_helper"

// ** for Loading Spinner
export const showLoading = createAsyncThunk(
  "appPayCards/showLoading",
  async (status) => {
    return status
  }
)

export const getUserPayCards = createAsyncThunk(
  "appPayCards/getUserPayCards",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_USER_POLICE_STATION_PAY_CARD}${id}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const getGroupPayCards = createAsyncThunk(
  "appPayCards/getGroupPayCards",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_GROUP_POLICE_STATION_PAY_CARD}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const addUserPayCard = createAsyncThunk(
  "appPayCards/addUserPayCard",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.CREATE_USER_POLICE_STATION_PAY_CARD, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Pay Card added Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const addGroupPayCard = createAsyncThunk(
  "appPayCards/addGroupPayCard",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.CREATE_GROUP_POLICE_STATION_PAY_CARD, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Pay Card added Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const getRemunerationTypes = createAsyncThunk(
  "appPayCards/getRemunerationTypes",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_LLA_CODES}Renumeration Type`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const appPayCardsSlice = createSlice({
  name: "appPayCards",
  initialState: {
    remunerationTypes: [],
    userPayCards: [],
    groupsPayCards: []
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRemunerationTypes.fulfilled, (state, action) => {
        state.remunerationTypes = action.payload
      })
      .addCase(getUserPayCards.fulfilled, (state, action) => {
        state.userPayCards = action.payload
      })
      .addCase(getGroupPayCards.fulfilled, (state, action) => {
        state.groupsPayCards = action.payload
      })
      .addCase(showLoading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export const { selectEvent } = appPayCardsSlice.actions

export default appPayCardsSlice.reducer 

// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import useJwt from "../../../auth/jwt/useJwt"

import * as url from "../../../helpers/url_helper"

// ** for Loading Spinner
export const showLoading = createAsyncThunk(
  "appDashboard/showLoading",
  async (status) => {
    return status
  }
)

export const getAllData = createAsyncThunk(
  "appDashboard/getAllData",
  async (id, { dispatch }) => {
    const response = await useJwt.get(
      `${url.GET_USERS}${id !== undefined ? id : ""}`
    )
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const getFormData = createAsyncThunk(
  "appDashboard/getFormData",
  async (obj, { dispatch }) => {
    const response = await useJwt.get(
      `${obj.api_url}${obj.id !== undefined ? `?days=${obj.id}` : ""}`
    )
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const getMonthlySubmissions = createAsyncThunk(
  "appDashboard/getMonthlySubmissions",
  async (api_url, { dispatch }) => {
    const response = await useJwt.get(
      `${api_url}`
    )
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const getSubmissions = createAsyncThunk(
  "appDashboard/getSubmissions",
  async (obj, { dispatch }) => {
    const response = await useJwt.get(
      `${obj.api_url}${obj.id !== undefined ? `?days=${obj.id}` : ""}`
    )
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const appDashboardSlice = createSlice({
  name: "appDashboard",
  initialState: {
    data: [],
    formData: null,
    monthlySubmission: null,
    submissions: [],
    isLoading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getFormData.fulfilled, (state, action) => {
        state.formData = action.payload
      })
      .addCase(getMonthlySubmissions.fulfilled, (state, action) => {
        state.monthlySubmission = action.payload.monthly_submissions
      })
      .addCase(getSubmissions.fulfilled, (state, action) => {
        state.submissions = action.payload.submissions
      })
      .addCase(showLoading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export default appDashboardSlice.reducer 

// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import useJwt from "../../../auth/jwt/useJwt"
import * as url from "../../../helpers/url_helper"
import toast from "react-hot-toast"
import ToastContent from "../../ui-elements/toast"
// import moment from 'moment'
import moment from "moment-timezone"
// ** for Loading Spinner
export const showLoading = createAsyncThunk(
  "appGovInstitute/showLoading",
  async (status) => {
    return status
  }
)

export const getAllData = createAsyncThunk(
  "appGovInstitute/getAllData",
  async (id, { dispatch }) => {
    const response = await useJwt.get(url.GET_GOV_INSTITUE)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const updateGov = createAsyncThunk(
  "appGovInstitute/updateInstitute",
  async (data, { dispatch }) => {
    await useJwt
      .put(url.UPDATE_GOV_INSTITUE, data)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Gov Institute Updated Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(getAllData())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })
    return permission
  }

)

export const updateGovContact = createAsyncThunk(
  "appGovInstitute/updateInstituteContact",
  async (data, { dispatch }) => {
    await useJwt
      .put(url.UPDATE_GOV_INSTITUE_CONTACT, data)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Contact Updated Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(getAllData())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })
    return permission
  }

)

export const getTypes = createAsyncThunk(
  "appGovInstitute/getTypes",
  async (id, { dispatch }) => {
    const response = await useJwt.get(url.GET_GOV_INSTITUE_TYPES)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const addInstitute = createAsyncThunk(
  "appGovInstitute/addInstitute",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.ADD_GOV_INSTITUE, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Institute Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        await dispatch(getAllData())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const getAllActivityCards = createAsyncThunk(
  "appGovInstitute/getAllActivityCards",
  async (id, { dispatch }) => {
    const response = await useJwt.get(url.GET_ALL_ACTIVITYCARDS)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const addActivityCard = createAsyncThunk(
  "appGovInstitute/addActivityCard",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.CREATE_ACTIVITYCARD, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Activity Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        await dispatch(getAllData())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const updateActivityCardStatus = createAsyncThunk(
  "appGovInstitute/updateActivityCardStatus",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.UPDATE_ACTIVITYCARD_STATUS, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Status Updated Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        await dispatch(getAllData())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const getActivityTypes = createAsyncThunk(
  "appGovInstitute/getActivityTypes",
  async (id, { dispatch }) => {
    const response = await useJwt.get(url.GET_ACTIVITY_TYPES)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const getCurrencyTypes = createAsyncThunk(
  "appGovInstitute/getCurrencyTypes",
  async (id, { dispatch }) => {
    const response = await useJwt.get(url.GET_CURRENCY_TYPES)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const getChargeTypes = createAsyncThunk(
  "appGovInstitute/getChargeTypes",
  async (id, { dispatch }) => {
    const response = await useJwt.get(url.GET_CHARGE_TYPES)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const getInstituteDutiesByDate = createAsyncThunk(
  "appGovInstitute/getInstituteDutiesByDate",
  async (data, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_INSTITUE_DUTIES_BY_DATES}?institute_id=${data.institute_id}&date=${data.date}`)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const getInstituteContacts = createAsyncThunk(
  "appGovInstitute/getInstituteContacts",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_INSTITUTE_CONTACTS}?institute_id=${id}`)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const addInstituteContact = createAsyncThunk(
  "appGovInstitute/addInstituteContact",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.ADD_INSTITUTE_CONTACTS, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Contact Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        await dispatch(getAllData())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const getInstituteActivities = createAsyncThunk(
  "appGovInstitute/getInstituteActivities",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_INSTITUE_ACTIVITIES}?institute_id=${id}`)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const getSchemes = createAsyncThunk(
  "appGovInstitute/getSchemes",
  async (id, { dispatch }) => {
    const response = await useJwt.get(url.GET_POLICE_STATION_SCHEMES)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const addCurrentRateToSchemes = createAsyncThunk(
  "appGovInstitute/addCurrentRateToSchemes",
  async (form, { dispatch }) => {
    await useJwt.put(url.ADD_CURRENT_RATE_POLICE_STATION_SCHEMES, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Current Rate Added Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        await dispatch(getSchemes())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })
    return form
  }
)

export const addFutureRateToSchemes = createAsyncThunk(
  "appGovInstitute/addCurrentRateToSchemes",
  async (form, { dispatch }) => {
    await useJwt.put(url.ADD_FUTURE_RATE_POLICE_STATION_SCHEMES, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Future Rate Added Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        await dispatch(getSchemes())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })
    return form
  }
)


export const addScheme = createAsyncThunk(
  "appGovInstitute/addScheme",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.ADD_POLICE_STATION_SCHEMES, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Scheme Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        await dispatch(getSchemes())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const appGovInstituteSlice = createSlice({
  name: "appGovInstitute",
  initialState: {
    allData: [],
    isLoading: false,
    instituteTypes: [],
    activityCards: [],
    currencyTypes: [],
    chargeTypes: [],
    instituteDutiesByDate: [],
    instituteActivities: [],
    instituteContacts: [],
    activityTypes: [],
    schemes: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(showLoading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
      .addCase(getTypes.fulfilled, (state, action) => {
        state.instituteTypes = action.payload
      })
      .addCase(getAllActivityCards.fulfilled, (state, action) => {
        state.activityCards = action.payload
      })
      .addCase(getCurrencyTypes.fulfilled, (state, action) => {
        const Types = []
        action.payload.options.map((item) => (
          item.optionValues.map((val) => (
            Types.push({
              label: val.label,
              value: val._id
            })
          )
          ))
        )
        state.currencyTypes = Types
      })
      .addCase(getChargeTypes.fulfilled, (state, action) => {
        const Types = []
        action.payload.options.map((item) => (
          item.optionValues.map((val) => (
            Types.push({
              label: val.label,
              value: val._id
            })
          )
          ))
        )
        state.chargeTypes = Types
      })
      .addCase(getInstituteDutiesByDate.fulfilled, (state, action) => {
        const TypesDut = []
        action.payload.map((item) => {
          const startDate = moment(item.startTime, "DD-MM-YYYY")
          let newLabel = ''
          if (startDate.isSame(moment(item.endTime, "DD-MM-YYYY"))) {
            newLabel = `${moment(item.startTime).tz("Europe/London").format("dddd, MMM Do YYYY")} - ${moment(item.startTime).tz("Europe/London").format('HH:mm')} - ${moment(item.endTime).tz("Europe/London").format('HH:mm')} ${item.dutyOwner.name}`
          } else {
            newLabel = `${moment(item.startTime).tz("Europe/London").format("dddd, MMM Do YYYY")} - ${moment(item.endTime).tz("Europe/London").format("dddd, MMM Do YYYY")} - ${moment(item.startTime).tz("Europe/London").format('HH:mm')} - ${moment(item.endTime).tz("Europe/London").format('HH:mm')} ${item.dutyOwner.name}`
          }
          TypesDut.push({ value: item.dutyID, label: newLabel })
        })
        state.instituteDutiesByDate = TypesDut
      })
      .addCase(getInstituteContacts.fulfilled, (state, action) => {
        const TypesDut = []
        action.payload.map((item) => (
          TypesDut.push({ value: item._id, label: item.Name, ...item })
        ))
        state.instituteContacts = TypesDut
      })
      .addCase(getInstituteActivities.fulfilled, (state, action) => {
        const TypesAct = []
        action.payload.map((item) => (
          TypesAct.push({ value: item.ActivityForeignID, label: item.ActivityName, ...item })
        ))
        state.instituteActivities = TypesAct
      })
      .addCase(getActivityTypes.fulfilled, (state, action) => {
        const Types = []
        action.payload.map((item) => (
          Types.push({
            label: item.label,
            value: item._id
          })
        )
        )
        state.activityTypes = Types
      })
      .addCase(getSchemes.fulfilled, (state, action) => {
        state.schemes = action.payload
      })
  }
})

export default appGovInstituteSlice.reducer 

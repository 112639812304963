// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import useJwt from "../../../auth/jwt/useJwt"
import * as url from "../../../helpers/url_helper"
import toast from 'react-hot-toast'
import ToastContent from '../../ui-elements/toast'

// ** for Loading Spinner
export const showLoading = createAsyncThunk(
  "appdirections/showLoading",
  async (status) => {
    return status
  }
)

export const fetchEvents = createAsyncThunk(
  "appdirections/fetchEvents",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_DIRECTIONS}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const fetchClientDates = createAsyncThunk(
  "appdirections/fetchClientDates",
  id => {
    return id
  }
)

export const fetchHearningTypes = createAsyncThunk(
  "appdirections/fetchHearningTypes",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_HEARING_TYPES}`)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const addEvent = createAsyncThunk(
  "appdirections/addEvent",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.ADD_NEW_DIRECTION, form)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Direction Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(fetchEvents())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const updateEvent = createAsyncThunk(
  "appdirections/updateEvent",
  async (form, { dispatch }) => {
    await useJwt
      .put(url.UPDATE_DIRECTION, form)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Direction Updated Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(fetchEvents())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)


export const deleteEvent = createAsyncThunk('appdirections/deleteEvent', async (form, { dispatch }) => {
  await useJwt.del(`${url.DELETE_DIRECTION}`, form)
    .then(async () => {
      toast.success(() => (
        <ToastContent message="Direction has been Deleted" />
      ), {
        position: "top-center",
        reverseOrder: false
      })
      await dispatch(fetchEvents())
      dispatch(showLoading(false))
    })
    .catch((res) => {
      dispatch(showLoading(false))
      toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
        position: "top-center",
        reverseOrder: false
      })
    })
  return form
})


export const appdirectionsSlice = createSlice({
  name: "appdirections",
  initialState: {
    selectedCalendars: [],
    events: [],
    filteredEvents: [],
    filteredTypeEvents: [],
    hearingTypes: [],
    isLoading: false,
    selectedEvent: {},
    eventTypes: [],
    selectedClient: null,
    selectedInstitute: null
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        const newEvents = []
        if (Object.keys(action.payload).length !== 0) {
          action.payload.map((item) => newEvents.push({
            _id: item._id,
            title: `${item.label}`,
            start: item.directionDateTime,
            end: item.directionDateTime,
            textColor: item.meta ? item.meta.color ? '#ffffff' : '' : '',
            borderColor: item.meta ? item.meta.color ? item.meta.color : '#4b4b4b' : '#4b4b4b',
            color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            display: 'block',
            extendedProps: {
              client: item.client !== null ? item.client._id : null,
              form: item.form ? item.form : null,
              color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            }
          }))
        }
        state.events = newEvents
        state.filteredEvents = newEvents

      })
      .addCase(fetchClientDates.fulfilled, (state, action) => {
        state.selectedClient = action.payload
        const UpdatedArr = []
        if (action.payload !== null) {
          state.events.map((item) => {
            if (item.extendedProps.client === action.payload.value) {
              UpdatedArr.push(item)
            }
          })
          state.filteredEvents = UpdatedArr
        } else {
          state.filteredEvents = state.events
        }

      })
      .addCase(showLoading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export const { selectEvent } = appdirectionsSlice.actions

export default appdirectionsSlice.reducer 

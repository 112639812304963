import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import { socket } from '../../../socket'
import * as Url from '../../../helpers/url_helper';

// In-memory cache object
const apiCache = {};

// List of URLs to cache
const urlsToCache = [
  Url.GET_GOV_INSTITUE,
  Url.GET_GOV_INSTITUE_TYPES,
  Url.GET_POLICE_STATION_SCHEMES,
  Url.GET_ACTIVITY_TYPES,
  Url.GET_CURRENCY_TYPES,
  Url.GET_CHARGE_TYPES
];

// Function to check the validity of cached data
function isDataValid(data) {
  const maxAge = process.env.REACT_APP_API_CACHE_TIMER_MINS * 60 * 1000; // 10 minutes
  const lastUpdated = data.__cachedAt;

  if (!lastUpdated || Date.now() - lastUpdated > maxAge) {
    return false;
  }
  return true;
}

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    if (config.method === 'get' && urlsToCache.includes(config.url)) {
      const cachedData = apiCache[config.url];

      if (cachedData) {
        if (isDataValid(cachedData)) {
          return Promise.resolve({
            data: cachedData,
            status: 200,
            statusText: 'OK',
            headers: config.headers,
            config: config,
            request: config,
          });
        }
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
axios.interceptors.response.use(
  (response) => {
    if (response.config.method === 'get' && urlsToCache.includes(response.config.url)) {
      // Ensure the data includes a timestamp before caching it
      response.data.__cachedAt = Date.now();
      apiCache[response.config.url] = response.data;
    }
    return response;
  },
  (error) => Promise.reject(error)
);

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        // ** Get token from localStorag
        const accessToken = config.url === '/auth/verifyOneTimeCode' ? this.getPreAuthToken() : config.url === '/auth/refreshToken' ? this.getRefreshToken() : this.getToken()
        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) 
        if (response && response.status === 401) {

          if (config.url === '/auth/refreshToken') {
            this.logout()
          } else {
            if (!this.isAlreadyFetchingAccessToken) {
              this.isAlreadyFetchingAccessToken = true
              this.refreshToken().then(r => {
                this.isAlreadyFetchingAccessToken = false
                // ** Update accessToken in localStorage
                this.setToken(r.data.payload.PAT)
                this.setRefreshToken(r.data.payload.refreshToken)
                socket.auth.token = r.data.payload.PAT
                socket.connect()

                this.onAccessTokenFetched(r.data.payload.PAT)
              })
            }
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        // ** if (status === 403) 
        if (response && response.status === 403) {
          window.location.href = "/auth/not-auth";
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }
  logout() {
    localStorage.removeItem('userData')
    localStorage.removeItem('Permissions')
    localStorage.removeItem('preAuth')
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.storageRefreshTokenKeyName)
    window.location.href = '/login'
  }
  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  getPreAuthToken() {
    return localStorage.getItem(this.jwtConfig.storagePreAuthTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  otp(...args) {
    return axios.post(this.jwtConfig.OTPEndpoitn, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return axios.get(this.jwtConfig.refreshEndpoint)
  }

  async get(url, config = {}) {
    return await axios.get(url, { ...config })
      .then(response => response.data)
      .catch(function (e) {
        return Promise.reject(e)
      })
  }

  async getWithBody(url, data, config = {}) {
    return await axios.get(url, data, { ...config })
      .then(response => response.data)
      .catch(function (e) {
        return Promise.reject(e)
      })
  }

  async post(url, data, config = {}) {
    return axios
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
      .catch(function (e) {
        return Promise.reject(e)
      })
  }

  async postDuty(url, data, config = {}) {
    return axios
      .post(url, data, { ...config })
      .then(response => response.data)
      .catch(function (e) {
        return Promise.reject(e)
      })
  }

  async postfile(url, data, config) {
    return axios
      .post(url, data, config)
      .then(response => response.data)
  }

  async put(url, data, config = {}) {
    return axios
      .put(url, { ...data }, { ...config })
      .then(response => response.data)
      .catch(function (e) {
        return Promise.reject(e)
      })
  }

  async del(url, data, config = {}) {
    return await axios
      .delete(url, { ...data }, { ...config })
      .then(response => response.data)
      .catch(function (e) {
        return Promise.reject(e)
      })
  }

}
